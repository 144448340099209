import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";

Vue.use(VueRouter);



const routes = [
  // {
  //   path: "/",
  //   name: "FrontPage2",
  //   component: () => import("../views/FrontPage2.vue"),
  // },
  /*{
    path: "/",
    name: "FrontPage2",
    component: () => import("../views/PreSlidesPage.vue"),
  },*/
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/main",
    name: "FrontPage",
    component: () => import("../views/FrontPage.vue"),
  },
  {
    path: "/slm-ptn",
    name: "PagePartner",
    component: () => import("../views/PagePartner.vue"),
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/home2",
    name: "Home2",
    component: () => import("../views/Home2.vue"),
  },
  {
    path: "/services",
    name: "PageServices",
    component: () => import("../views/PageServices.vue"),
  },
  {
    path: "/your-home",
    name: "PageYourHome",
    component: () => import("../views/PageYourHome.vue"),
  },
  {
    path: "/dvla",
    name: "PageDvla",
    component: () => import("../views/PageDvla.vue"),
  },
  {
    path: "/general-info-2",
    name: "PageGeneralInfo2",
    component: () => import("../views/PageGeneralInfo2.vue"),
  },
  {
    path: "/insurance",
    name: "PageInsurance",
    component: () => import("../views/PageInsurance.vue"),
  },
  {
    path: "/pets",
    name: "PagePets",
    component: () => import("../views/PagePets.vue"),
  },
  {
    path: "/general-info-3",
    name: "PageGeneralInfo3",
    component: () => import("../views/PageGeneralInfo3.vue"),
  },
  {
    path: "/councils",
    name: "PageCouncil",
    component: () => import("../views/PageCouncil.vue"),
  },
  {
    path: "/mobile-providers",
    name: "PageMobileProviders",
    component: () => import("../views/PageMobileProviders.vue"),
  },
  {
    path: "/loyalties",
    name: "PageLoyalties",
    component: () => import("../views/PageLoyalties.vue"),
  },
  {
    path: "/banks",
    name: "PageBanks",
    component: () => import("../views/PageBanks.vue"),
  },
  {
    path: "/general-info-5",
    name: "PageGeneralInfo5",
    component: () => import("../views/PageGeneralInfo5.vue"),
  },
  {
    path: "/opticians",
    name: "PageOpticians",
    component: () => import("../views/PageOpticians.vue"),
  },
  {
    path: "/about-you",
    name: "PageAboutYou",
    component: () => import("../views/PageAboutYou.vue"),
  },
 /* {
    path: "/about-you-2",
    name: "PageAboutYou2",
    component: () => import("../views/PageAboutYou2.vue"),
  },*/
  {
    path: "/disclaimer",
    name: "PageDisclaimer",
    component: () => import("../views/PageDisclaimer.vue"),
  },
  {
    path: "/pre-review",
    name: "PreFinalPage",
    component: () => import("../views/PreFinalPage.vue"),
  },
  {
    path: "/review",
    name: "PageReview",
    component: () => import("../views/PageReview.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let pageNamePrevious = "";

//Loop that is executed when there are changes in the route
router.beforeEach((to, from, next) => {
  // console.log(to, from, next)

  // console.log(store.state.formStore);
  //Message in Main Button
  const { referrer, freeUpdate } = store.state.formStore;

  store.commit("setField", [
    `messageButton`,
    to.name == "PageReview" &&
    (referrer == "TaylorRose" ||
      freeUpdate == true ||
      referrer == "AddressUpdater" ||
      referrer.includes( "anopy") ||
      referrer == "FamilyUpdater")
      ? "COMPLETE"
      : to.name !== "PageReview"
      ? "Continue"
      : "Buy Now",
  ]);

  next();
});
//Loop that is executed when there are changes in the route

// when the user navigates backwards in navigation
router.afterEach((to, from, next) => {
  // console.log("router.afterEach",to, from);
  store.commit("addVisitedPage", to.name);

  pageNamePrevious = from.name;
  //
}); //when the user navigates backwards in navigation

const navigateTo = (route) => {
  let { informBroadbandMedia, informEnergy, informInsurance } = store.state.formStore;

  router
    .push(route)
    .catch((e) => console.error(e))
    .finally(() => {
      // if (
      //   pageNamePrevious == "PageAboutYou2" &&
      //   (informBroadbandMedia || informEnergy || informInsurance)
      // ) {
      //   store.dispatch("formStore/homeSetupPayload");
      // } else {
      //   store.dispatch("formStore/savePartialData");
      // }

      store.dispatch("formStore/savePartialData");

    });
};

export { router, navigateTo };
