<template>
  <div class="action-sheet-nin col-12 p-5 pt-4 pb-3">
    <div class="d-block">
      <div class="text-center mb-4">
        <img :src="require('@/assets/tooltips/tooltip-shield.png')" width="80px" alt="" />
      </div>

      <!-- <div class="mb-4 text-justify">
        <p class="fs-sm mb-1 fw-bold">HMRC requires this 👇</p>
        <p class="fs-sm mb-0">
          Your National Insurance Number is requested by government institutions to verify your
          identity. We never share this with any other organisations,
        </p>
      </div> -->

      <div class="mb-2 text-justify">
        <p class="fs-sm mb-1 fw-bold">In safe hands 🔒</p>
        <p class="fs-sm mb-0">
          As with all your other details, we will never share them with third parties. You can learn
          more by taking a look at our principles on data ethics.
        </p>
      </div>

    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "ActionSheetNIN",
  mounted() {},
  methods: {
    // closeActionSheet() {
    //   let pushbar = new Pushbar({
    //     blur: true,
    //     overlay: true,
    //   });
    //  pushbar.close('action-sheet')
    // },
  },
  computed: {
    ...mapState([""]),
  },
};
</script>
<style lang="scss">
.action-sheet-nin {
  color: #212534;
}
</style>
