<template>
  <div class="action-sheet-yourhome col-12 p-5 pt-4 pb-3">
    <div class="d-block">
      <div class="text-center mb-4">
        <img :src="require('@/assets/tooltips/tooltip-good-quality.png')" width="120px" alt="" />
      </div>

      <div class="mb-4 text-justify">
        <p class="fs-sm mb-1 fw-bold">Why might my company charge a fee </p>
        <p class="fs-sm mb-0">
          When you move house, your risk might change. Some companies may need to re-calculate your premium. This may incur a small admin fee.
        </p>
      </div>

      <div class="mb-4 text-justify">
        <p class="fs-sm mb-1 fw-bold">How will I find out?</p>
        <p class="fs-sm mb-0">
          As part of your SlothMove, we'll inform your insurance provider about your move and they'll reach out if they need to re-quote you or would need a small admin fee
        </p>
      </div>

    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "ActionSheetInsurance",
  mounted() {},
  methods: {
    // closeActionSheet() {
    //   let pushbar = new Pushbar({
    //     blur: true,
    //     overlay: true,
    //   });
    //  pushbar.close('action-sheet')
    // },
  },
  computed: {
    ...mapState([""]),
  },
};
</script>
<style lang="scss">
.action-sheet-yourhome {
  color: #212534;
}
</style>
