import Vue from "vue";
import Vuex from "vuex";
import formStore from "./form";
import emailjs from "emailjs-com";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    logo:"partners/logo.png",
    titleNavbar: "",
    routeAssigned: undefined,
    //Navbar progress tracker
    step1: true,
    step2: false,
    step3: false,
    step4: false,
    //Main Button
    isLoading: false,
    messageButton: "COMPLETE",
    zIndex: 9,
    visitedRoutes: [],
    currentTooltip: ''
  },
  mutations: {
    setField(state, [fieldName, payload]) {
      state[fieldName] = payload;
    },
    addVisitedPage(state, route) {
      !state.visitedRoutes.find((i) => i == route) && state.visitedRoutes.push(route);
    },
  },
  actions: {
    submitForm({ rootState, state, rootGetters, commit }) {
      fetch(state.formStore.baseURL + "/processMove/run", {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          sessionId: rootState.formStore.userId,
          collectedData: Object.assign({}, rootGetters["formStore/formattedFormResults"]),
        }),
        body: JSON.stringify({
          data: {
            sessionId: rootState.formStore.userId,
            collectedData: Object.assign({}, rootGetters["formStore/formattedFormResults"]),
          },
        }),
      })
        .then((res) => {
          const { referrer } = state.formStore
          if ( referrer === "TaylorRose" && state.formStore.trId !== "-1") {
            //window.location = 'https://trmw-uat.taylor-rose.co.uk/MSPortal/SlothMove/CompleteProcess?portalslothmoveid='+ state.formStore.trId
            // window.location =
            //   "https://trmw.taylor-rose.co.uk/MSPortal/SlothMove/CompleteProcess?portalslothmoveid=" +
            //   state.formStore.trId;
            // window.location = "https://slothmove.com/submission-completion-ss/";
            window.location = "https://slothmove.com/submission-completion-dynamic/"
          } else if (state.formStore.utilFlow) {
            window.location = "https://slothmove.com/submission-completion-dynamic/";
          } else if (state.formStore.premium) {
            window.location = "https://slothmove.com/submission-completion-dynamic/";
            //window.location = 'https://www.myslothmove.com/login?red='+window.btoa(state.formStore.email+':'+state.formStore.password)
          } else {
            window.location = "https://slothmove.com/submission-completion-dynamic/";
          }
        })
        .catch((error) => {
            if (state.formStore.referrer === "TaylorRose" && state.formStore.trId !== "-1") {
              //window.location = 'https://trmw-uat.taylor-rose.co.uk/MSPortal/SlothMove/CompleteProcess?portalslothmoveid='+ state.formStore.trId
              window.location =
                "https://trmw.taylor-rose.co.uk/MSPortal/SlothMove/CompleteProcess?portalslothmoveid=" +
                state.formStore.trId;
            } else if (state.formStore.premium) {
              window.location = "https://slothmove.com/submission-completion-ss/";
            } else {
              window.location = "https://slothmove.com/submission-completion/";
            }
        });
    },
  },
  modules: {
    formStore,
  },
  getters: {
    stateComplete: (state) => {
      // let object = {};
      let objState = Object.assign({...state});
      // let formStore = state.formStore;

      delete objState.formStore['warningSlothPlus']

      // delete objState.formStore;

      // for (const [key, value] of Object.entries(formStore)) {
      //   // console.log(`${key}: ${value}`);
      //   if (value && value !== "") {
      //     object[key] = value;
      //   }
      // }

      // return { ...objState, formStore: object };

      return objState
    },
  },
});
