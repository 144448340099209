<template>
  <div class="action-sheet-yourhome col-12 p-5 pt-4 pb-3">
    <div class="d-block">
      <div class="text-center mb-4">
        <img :src="require('@/assets/tooltips/tooltip-phone.png')" width="80px" alt="" />
      </div>
  
      <div class="mb-4 text-justify">
        <p class="fs-sm mb-1 fw-bold">Speak to a human 📞</p>
        <p class="fs-sm mb-0">
          Our friendly home setup team will give you a call to help you with a list of services, including:
        </p>
        <ul class="fs-sm">
          <li>Gas & electricity</li>
          <li>Boiler cover</li>
          <li>Broadband</li>
          <li>Insurance</li>
          <li>Storage</li>
          <li>Media</li>
        </ul>
      </div>

      <div class="mb-4 text-justify">
        <p class="fs-sm mb-1 fw-bold">Sort it online 💻</p>
        <p class="fs-sm mb-0">
          We'll help you set everything-up digitally in one place, from broadband, energy and more
        </p>
      </div>

    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "ActionSheetYourHome",
  mounted() {},
  methods: {
    // closeActionSheet() {
    //   let pushbar = new Pushbar({
    //     blur: true,
    //     overlay: true,
    //   });
    //  pushbar.close('action-sheet')
    // },
  },
  computed: {
    ...mapState([""]),
  },
};
</script>
<style lang="scss">
.action-sheet-yourhome {
  color: #212534;
}
</style>
