export const scrollToTop = () => {
    /* const rootEle = document.documentElement;
    rootEle.scrollTo({
      top: 0,
      behavior: "smooth",
    }); */
    window.scrollTo({ top: 0, behavior: 'smooth' })
  };

export const scrollToBottom = () =>{
  window.scrollTo(0, document.body.scrollHeight);
}