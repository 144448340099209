import Vue from "vue";
import App from "./App.vue";

import store from "./store";
import { router } from "./router/index";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "pretty-checkbox/src/pretty-checkbox.scss";
import PrettyCheckbox from "pretty-checkbox-vue";
Vue.use(PrettyCheckbox);

Vue.config.productionTip = false;

Vue.prototype.$urlBucket = "https://drmig4hxr8n3m.cloudfront.net";

Vue.component("v-select", vSelect);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
