<template>
  <div class="navbar-sloth" id="navbarSloth">
    <div class="row">
      <div class="col d-flex justify-content-between w-100 mb-4 mt-2">
        <div class="d-flex align-items-center h-100">
          <i
            class="fas fa-angle-left"
            @click="backRoute()"
          ></i>
        </div>

        <img
          :src="`${urlBucket}/form/${logo}`"
          width="95px"
          class="img-fluid"
          style="display: none"
          @load="
            (e) => {
              e.target.style.display = 'block';
              hideSpinner('spinner-navbar');
            }
          "
        />

        <div id="spinner-navbar" class="text-center">
          <img src="/assets/img/spinner-loading.gif" width="80px" alt="" />
        </div>

        <div></div>
      </div>
    </div>
    <h4 class="fs-xl" :style="{ visibility: $route.name !== 'PreFinalPage' ? 'visible' : 'hidden' }">{{ titleNavbar }}</h4>

    <div
      class="row"
      style="padding-left: 10px; padding-right: 10px"
      :style="{ visibility: $route.name !== 'PreFinalPage' ? 'visible' : 'hidden' }"
    >
      <div
        :class="`col step ${
          step1 && !step2
            ? 'step-active-2 animate__animated animate__bounceIn'
            : step1 && step2
            ? 'step-active-1'
            : ''
        }`"
      ></div>
      <div
        :class="`col step ${step2 ? 'step-active-2 animate__animated animate__bounceIn' : ''}`"
      ></div>
      <div
        :class="`col step ${step3 ? 'step-active-3 animate__animated animate__bounceIn' : ''}`"
      ></div>
      <div
        :class="`col step ${step4 ? 'step-active-4 animate__animated animate__bounceIn' : ''}`"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { scrollToTop } from "../utils/scrollToTop";
import { deleteRoute } from "../router/setRoute.js";

export default {
  data() {
    return {};
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    backRoute() {
      deleteRoute(this.$route.path);

      // this.$store.commit("formStore/setField", [`pagesVisited`, array]);

      this.$router.back();
      // if (this.$route.name != "Home") {
      //   this.$router.back();
      // }
    },
  },
  mounted() {},
  computed: {
    ...mapState(["logo", "titleNavbar", "step1", "step2", "step3", "step4"]),
    ...mapState("formStore", ["referrer", "urlBucket"]),
  },
};
</script>

<style lang="scss">
.navbar-sloth {
  .step {
    height: 12px;
    margin: 3px;
    background: rgb(255, 255, 255);
    border-radius: 15px;
  }

  .step-initial {
    background: white;
    background: linear-gradient(
      90deg,
      rgba(10, 119, 254, 1) 0%,
      rgba(10, 119, 254, 0.4962359943977591) 51%,
      rgba(179, 0, 255, 1) 100%
    );
    transform: perspective(1px) translateZ(0);
  }

  .step-active-1 {
    background: white;
    background: linear-gradient(
      90deg,
      rgba(35, 169, 215, 1) 1%,
      rgba(35, 169, 215, 1) 60%,
      rgba(157, 130, 235, 1) 100%
    );
    transform: perspective(1px) translateZ(0);
  }

  .step-active-2 {
    background: white;
    background: linear-gradient(
      90deg,
      rgba(10, 119, 254, 1) 0%,
      rgba(10, 119, 254, 0.4962359943977591) 65%,
      rgba(179, 0, 255, 1) 100%
    );
    transform: perspective(1px) translateZ(0);
  }

  .step-active-3 {
    background: white;
    background: linear-gradient(90deg, #8a26d8 0%, rgba(171, 4, 249, 1) 45%, #1f976e 100%);
    transform: perspective(1px) translateZ(0);
  }

  .step-active-4 {
    background: white;
    background: linear-gradient(
      90deg,
      rgba(31, 151, 110, 1) 0%,
      rgba(112, 190, 84, 1) 85%,
      rgba(178, 216, 68, 1) 100%
    );
    transform: perspective(1px) translateZ(0);
  }

  i {
    cursor: pointer;
    font-size: 25px;
  }
}
</style>
