<template>
  <div class="action-sheet-phone-number col-12 p-5 pt-4 pb-3">
    <div class="d-block">
      <div class="text-center mb-4">
        <img :src="require('@/assets/tooltips/tooltip-shield.png')" width="80px" alt="" />
      </div>

      <div class="mb-4 text-justify">
        <p class="fs-sm mb-1 fw-bold">Number for home setup 🏠</p>
        <p class="fs-sm mb-0">
          If you've selected to have a call with a friendly home setup agent your number is only used for this purpose.
        </p>
      </div>

      <div class="mb-2 text-justify">
        <p class="fs-sm mb-1 fw-bold">Address updates 🔒</p>
        <p class="fs-sm mb-0">
          Organisations use mobile numbers as a method to locate your account and identify the address update is genuine. We only share your number with the organisations you have explicitly stated and never with third-parties.
        </p>
      </div>

    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "ActionSheetPhoneNumber",
  mounted() {},
  methods: {
    // closeActionSheet() {
    //   let pushbar = new Pushbar({
    //     blur: true,
    //     overlay: true,
    //   });
    //  pushbar.close('action-sheet')
    // },
  },
  computed: {
    ...mapState([""]),
  },
};
</script>
<style lang="scss">
.action-sheet-phone-number {
  color: #212534;
}
</style>
