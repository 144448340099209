import CryptoJS from "crypto-js";

export const tiktokAnalytics = (customerEmail, eventTriggered, priceOffered) => {
  ttq.identify({
    email: CryptoJS.SHA256(customerEmail).toString(), // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
  });

  ttq.track(eventTriggered, {
    contents: [
      {
        content_id: "SlothMove",
        content_type: "Move", // string. Either product or product_group.
        content_name: "Move", // string. The name of the page or product. Example: "shirt".
      },
    ],
    value: priceOffered, // number.
    currency: "GBP",
  });
};
