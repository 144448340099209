<template>
  <button v-if="isTop" class="btn-scroll-top background-hover-primary" @click="scrollToTop()">
    <i class="fas fa-arrow-up"></i>
  </button>
</template>

<script>
import { scrollToTop } from '../utils/scrollToTop';

// import { onMounted } from "@vue/composition-api";
export default {
  data() {
    return {
      isTop: true,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll(e) {
    //   console.log(window.scrollY);
      if (window.scrollY == 0) {
        this.isTop = false;
      } else {
        this.isTop = true;
      }
    },
    scrollToTop:()=> scrollToTop()
  },
};
</script>

<style lang="scss">
.btn-scroll-top {
  display: none;
  cursor: pointer;
  background: #1cb0f6;
  width: 40px;
  border: none;
  padding: 10px 5px;
  color: #ffffff;
  font-size: 16px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.06em;
  text-transform: none;
  position: fixed;
  bottom: 5%;
  right: 30px;
  z-index: 99;
  transition: all 0.5s ease;

  @media screen and (max-width: 1050px) {
    display: block;
  }
}

.background-hover-primary:hover {
  background: lighten(#1cb0f6, 10%);
}
</style>
