<template>
  <div class="action-sheet-services col-12 p-5 pt-4 pb-3">
    <div class="d-block pt-5">
      <!-- <div class="text-center mb-4">
        <img :src="require('@/assets/tooltips/tooltip-energy.png')" width="80px" alt="" />
      </div> -->
  
      <div class="mb-3 text-justify">
        <p class="fs-sm mb-1 fw-bold">Selecting your updates 📍</p>
        <p class="fs-sm mb-0">
          Click one or more icons to update the address on your accounts, we will ask you more for your specific providers later on the form.
        </p>
      </div>

      <div class="mb-3 text-justify">
        <p class="fs-sm mb-1 fw-bold">How to remove updates ❌</p>
        <p class="fs-sm mb-0">
          Click the icon again to de-select any organizations
        </p>
      </div>

      <div class="mb-3 text-justify">
        <p class="fs-sm mb-1 fw-bold">Utility providers 💧</p>
        <p class="fs-sm mb-0">
          You can select your energy, water, council and other utility providers on the following page
        </p>
      </div>

    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "ActionSheetServices",
  mounted() {},
  methods: {
    // closeActionSheet() {
    //   let pushbar = new Pushbar({
    //     blur: true,
    //     overlay: true,
    //   });
    //  pushbar.close('action-sheet')
    // },
  },
  computed: {
    ...mapState([""]),
  },
};
</script>
<style lang="scss">
.action-sheet-services {
  color: #212534;
}
</style>
