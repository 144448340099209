<template>
  <div class="main-button mt-auto mb-3" v-if="!warningSlothPlus">
    <!-- :style="{ 'width': widthMainButton + 'px' }" -->

    <div class="">
      <button
        class="button-sloth mb-4"
        role="button"
        id="mainButton"
        :style="{
          'z-index': zIndex,
          'max-width': $route.name === 'PagePartner' ? '330px' : null,
        }"
        @click="toPage()"
      >
        <span class="fw-bold fs-la">{{ messageButton }}</span>
      </button>
    </div>

    <div class="text-center mt-3 mb-1" v-if="$route.path == '/' || $route.name == 'PreFinalPage' " >
      <img
        :src="`${this.$urlBucket}/form/assets/trustpilot-front2.png`"
        class="img-fluid"
        style="max-width: 70%;"
        alt=""
      />
    </div>

    <!-- TrustBox widget - Mini Carousel -->

<!--    <div class="trustpilot-container" :style="{ display: $route.name !== 'PreFinalPage' ? 'block' : 'none' }">
      &lt;!&ndash;v-if="$route.path !== '/'"  &ndash;&gt;
      <div
        class="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="539ad0ffdec7e10e686debd7"
        data-businessunit-id="5e5790377116b200012355a1"
        data-style-height="350px"
        data-style-width="100%"
        data-theme="dark"
        data-stars="5"
        data-review-languages="en"
      >
        <a href="https://uk.trustpilot.com/review/slothmove.com" target="_blank" rel="noopener"
          >Trustpilot</a
        >
      </div>
    </div>-->

    <!-- <div class="text-center mt-3 mb-1" v-if="$route.name === 'PageReview'">
      <img :src="require('@/assets/no-fuss-refund-policy.png')" class="img-fluid" alt="" />
    </div> -->

    <div class="text-center mt-3 mb-1" v-if="$route.name === 'PagePartner'">
      <img
        :src="`${this.$urlBucket}/form/assets/footer-trustpilot.png`"
        class="img-fluid"
        style="max-width: 215px"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MainButton",
  data() {
    return {};
  },
  mounted() {
    this.initTrustpilotWidget();
  },
  methods: {
    isFrontPage() {
      return window.location.pathname == "/";
    },
    initTrustpilotWidget() {
      let script = document.createElement("script");
      script.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
      script.id = "trustpilot";
      script.async = true;
      document.head.appendChild(script);
    },
    toPage() {
      this.routeAssigned && this.$router.push({ name: this.routeAssigned }).catch(() => {});
    },
  },
  computed: {
    ...mapState(["routeAssigned", "zIndex", "messageButton"]),
    ...mapState("formStore", ["warningSlothPlus"]),
  },
  watch: {
    $route(route) {
      if (route.name !== "FrontPage" && route.name !== "FrontPage2") {
       /* document.querySelector(".trustpilot-container").style.display = "block";
        this.initTrustpilotWidget();*/
      }
    },
  },
};
</script>

<style lang="scss">
.main-button {
  margin-top: 20px;
  margin-bottom: 10px;

  #mainButton {
    position: sticky;
    margin-top: 1rem;
    height: 57.5px;
  }

  .button-sloth {
    appearance: button;
    /*background-color: #15799b;
    color: #ffffff;
    */
    border: solid transparent;
    border-radius: 8px;
    border-width: 0 0 4px;
    box-sizing: border-box;
    cursor: pointer;
    letter-spacing: 0.8px;
    line-height: 20px;
    margin: 0;
    outline: none;
    overflow: visible;
    text-transform: uppercase;
    touch-action: manipulation;
    transform: translateZ(0);
    transition: filter 0.2s;
    user-select: none;
    -webkit-user-select: none;
    white-space: nowrap;
    width: 100%;
    margin-top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button-sloth:after {
    background-clip: padding-box;
    /* background-color: #1cb0f6; */
    border: solid transparent;
    border-radius: 8px;
    border-width: 0 0 4px;
    bottom: -4px;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  .button-sloth:main,
  .button-sloth:focus {
    user-select: auto;
  }

  .button-sloth:hover:not(:disabled) {
    filter: brightness(1.1);
    -webkit-filter: brightness(1.1);
  }

  .button-sloth:disabled {
    cursor: auto;
  }
}
</style>
