<template>
  <div class="action-sheet-energy col-12 p-5 pt-4 pb-3">
    <div class="d-block">
      <div class="text-center mb-4">
        <img :src="require('@/assets/tooltips/tooltip-energy.png')" width="80px" alt="" />
      </div>

      <div class="mb-3 text-justify">
        <p class="fs-sm mb-1 fw-bold">SlothMove magic 🔮</p>
        <p class="fs-sm mb-0">
          We help you identify the water providers for your previous and new homes. You can cancel, transfer or set up your bills with them.
        </p>
      </div>

      <div class="mb-3 text-justify">
        <p class="fs-sm mb-1 fw-bold">Good to know ❗️</p>
        <p class="fs-sm mb-0">
          Don't forget to read your meters on the day you move out and move in! Tell us later and we will pass it to your energy or water companies.
        </p>
      </div>

      <div class="text-justify">
        <p class="fs-sm mb-1 fw-bold">Energy ⚡️ </p>
        <p class="fs-sm mb-0">
          Energy doesn’t move home with you. When you move home, we close your energy account at your previous address. You automatically inherit the incumbent supplier at your new home, often at above-market rates. We recommend you switch your energy supplier or change your tariff.
        </p>
      </div>

      <!-- <div class="text-justify">
        <p class="fs-sm mb-1 fw-bold">Setting-up energy ⚡️</p>
        <p class="fs-sm mb-0">
          When moving home you automatically inherit the incumbent supplier, often at above-market rates. SlothMove can help you setup energy later.
        </p>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "ActionSheetEnergy",
  mounted() {},
  methods: {
    // closeActionSheet() {
    //   let pushbar = new Pushbar({
    //     blur: true,
    //     overlay: true,
    //   });
    //  pushbar.close('action-sheet')
    // },
  },
  computed: {
    ...mapState([""]),
  },
};
</script>
<style lang="scss">
.action-sheet-energy {
  color: #212534;
}
</style>
