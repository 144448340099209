var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-sloth",attrs:{"id":"navbarSloth"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex justify-content-between w-100 mb-4 mt-2"},[_c('div',{staticClass:"d-flex align-items-center h-100"},[_c('i',{staticClass:"fas fa-angle-left",on:{"click":function($event){return _vm.backRoute()}}})]),_c('img',{staticClass:"img-fluid",staticStyle:{"display":"none"},attrs:{"src":`${_vm.urlBucket}/form/${_vm.logo}`,"width":"95px"},on:{"load":(e) => {
            e.target.style.display = 'block';
            _vm.hideSpinner('spinner-navbar');
          }}}),_vm._m(0),_c('div')])]),_c('h4',{staticClass:"fs-xl",style:({ visibility: _vm.$route.name !== 'PreFinalPage' ? 'visible' : 'hidden' })},[_vm._v(_vm._s(_vm.titleNavbar))]),_c('div',{staticClass:"row",staticStyle:{"padding-left":"10px","padding-right":"10px"},style:({ visibility: _vm.$route.name !== 'PreFinalPage' ? 'visible' : 'hidden' })},[_c('div',{class:`col step ${
        _vm.step1 && !_vm.step2
          ? 'step-active-2 animate__animated animate__bounceIn'
          : _vm.step1 && _vm.step2
          ? 'step-active-1'
          : ''
      }`}),_c('div',{class:`col step ${_vm.step2 ? 'step-active-2 animate__animated animate__bounceIn' : ''}`}),_c('div',{class:`col step ${_vm.step3 ? 'step-active-3 animate__animated animate__bounceIn' : ''}`}),_c('div',{class:`col step ${_vm.step4 ? 'step-active-4 animate__animated animate__bounceIn' : ''}`})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center",attrs:{"id":"spinner-navbar"}},[_c('img',{attrs:{"src":"/assets/img/spinner-loading.gif","width":"80px","alt":""}})])
}]

export { render, staticRenderFns }