<template>
  <div class="action-sheet-eroll col-12 p-5 pt-4 pb-3 ">
    <div class="d-block">
      <div class="text-center mb-3">
        <img :src="require('@/assets/tooltips/tooltip-hand-like.png')" width="80px" alt="" />
      </div>
  
      <div class="mb-3 text-justify">
        <p class="fs-sm mb-1 fw-bold">Good to know  👇</p>
        <p class="fs-sm mb-0">
          Being on the electoral roll can improve your credit score. Once it is registered, you will get a confirmation email from the Electoral Office.
        </p>
      </div>

      <div class="mb-3 text-justify">
        <p class="fs-sm mb-1 fw-bold">What is the 'open register'? 🗳</p>
        <p class="fs-sm mb-0">
          The Open Register is a version of the electoral register. Businesses can use it to check your details and it is 100% optional to enlist in.
        </p>
      </div>

      <div class="mb-3 text-justify">
        <p class="fs-sm mb-1 fw-bold">Improve your credit score 📈</p>
        <p class="fs-sm mb-0">
          Credit reference agencies use your address as an influencing factor for your credit score. Updating the electoral roll will updating credit reference agencies and support your credit score.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "ActionSheetERoll",
  mounted() {},
  methods: {
    // closeActionSheet() {
    //   let pushbar = new Pushbar({
    //     blur: true,
    //     overlay: true,
    //   });
    //  pushbar.close('action-sheet')
    // },
  },
  computed: {
    ...mapState([""]),
  },
};
</script>
<style lang="scss">
.action-sheet-eroll{
  color: #212534;
}
</style>
