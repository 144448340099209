<template>
  <div id="app" class="container-fluid">
    <div class="row d-flex justify-content-center">
      <div class="main-container col-12 col-sm-10 col-md px-3 d-flex flex-column">
        <!--:style="{maxWidth: isFrontPage()}"  -->
        <Navbar
          v-if="
            $route.name !== 'PagePartner' &&
            $route.name !== 'FrontPage' &&
            $route.name !== 'FrontPage2'
          "
        />

        <div
          class="layout-router-view"
          :class="{ 'content-loaded': !isLoading }"
          style="visibility: hidden"
        >
          <router-view />
        </div>
        <MainButton />
      </div>
    </div>
    <!-- <ScrollToTop /> -->

    <aside data-pushbar-id="action-sheet" data-pushbar-direction="bottom">
      <div class="action-sheet container-fluid h-100">
        <div class="row justify-content-center h-100">
          <!-- Render Current Action Sheet -->
          <component v-bind:is="currentTooltip"></component>
          <div class="text-center mt-auto w-100" style="margin-bottom: 25px">
            <button class="text-black mb-1" data-pushbar-close>
              <img :src="require('@/assets/close-action-sheet.png')" width="65px" alt="" />
            </button>
          </div>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import "@/styles/main.scss";
import MainButton from "./components/MainButton";
import Navbar from "./components/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import { scrollToTop } from "./utils/scrollToTop";
import { mapState } from "vuex";
import Swal from "sweetalert2";
import pcToWater from "@/data/postCodeToWaterSupplier";

/*currentTooltip*/
import ActionSheetNIN from "./components/ActionSheetNIN";
import ActionSheetEnergy from "./components/ActionSheetEnergy";
import ActionSheetServices from "./components/ActionSheetServices";
import ActionSheetYourHome from "./components/ActionSheetYourHome";
import ActionSheetPhoneNumber from "./components/ActionSheetPhoneNumber";
import ActionSheetERoll from "./components/ActionSheetERoll";
import ActionSheetInsurance from "./components/ActionSheetInsurance.vue";
import { navigateTo } from "@/router/index";
import { isMobile } from "./utils/isMobile";
import { deleteRoute } from "./router/setRoute.js";
import { tiktokAnalytics } from "./utils/tiktokAnalytics.js";

/*currentTooltip*/

//Lucky Orange Script
// window.__lo_site_id = 310843;
// (function () {
//   var wa = document.createElement("script");
//   wa.type = "text/javascript";
//   wa.async = true;
//   wa.src = "https://d10lpsik1i8c69.cloudfront.net/w.js";
//   var s = document.getElementsByTagName("script")[0];
//   s.parentNode.insertBefore(wa, s);
// })();
//Lucky Orange Script

export default {
  name: "App",
  components: {
    Navbar,
    MainButton,
    ActionSheetNIN,
    ActionSheetEnergy,
    ActionSheetServices,
    ActionSheetYourHome,
    ActionSheetPhoneNumber,
    ActionSheetERoll,
    ActionSheetInsurance,
    // ScrollToTop
  },
  data() {
    return {
      tiktokStarted: false,
    };
  },

  mounted() {
    if (window.location.pathname !== "/" && window.location.pathname !== "/home") {
      if (window.location.pathname == "/review" || window.location.pathname == "/partner") {
        //;
      } else {
        location.replace("/");
      }
    }

    // ActionSheet Init
    const pushbar = new Pushbar({
      blur: true,
      overlay: true,
    });

    const resize = () => {
      const widthNavbar = document.getElementById("navbarSloth")?.offsetWidth;
      // this.$store.commit("setField", ["widthMainButton", widthNavbar]);
    };

    resize();

    window.onresize = resize;

    /* This event will fire when user press back or forward button. */
    window.onpopstate = (event) => {
      // change content
      // console.log("back event detected");
      if (this.$route.name == "Home") this.$store.commit("setField", ["step2", false]);

      if (this.$route.name == "PageServices") this.$store.commit("setField", ["step3", false]);

      if (this.$route.name == "PageAboutYou") this.$store.commit("setField", ["step4", false]);


      deleteRoute(this.$route.path);
    };
    /* This event will fire when user press back or forward button. */

    let backStripe = new URLSearchParams(document.location.search).get("backStripe");
    if (backStripe) {
      const obj = JSON.parse(this.getLargeCookie("userdata"));
      console.log("backStripe", obj);

      this.$store.commit("formStore/restoreForm", obj.formStore);

      navigateTo("/review");
    }
    // Article Integration
    const article = new URLSearchParams(document.location.search).get("article");

    if (article) {
      this.updateValue("article", article);
    }
    // Preform Integration
    const paramPreform = new URLSearchParams(document.location.search).get("completePre");

    if (paramPreform) {
      try{
        const objInfo = JSON.parse(window.atob(paramPreform));
        console.log("Preform Info", objInfo);

        const { email, completeAddressFrom, completeAddressTo, moveFromAddress, moveToAddress } =
          objInfo;

        this.updateValue("email", email);
        const councilAndWaterfromPreform = (water, council, obj) => {
          this.updateValue(water, pcToWater[obj.postcode.split(" ")[0]]);

          this.updateValue(council, obj.district ? obj.district : obj.county);
          this.updateValue("moveFromPostCode", completeAddressFrom.postcode);
          this.updateValue("moveToPostCode", completeAddressTo.postcode);
          this.updateValue("completeMoveFromAddress", completeAddressFrom);
          this.updateValue("completeMoveToAddress", completeAddressTo);
          this.updateValue("moveFromAddress", moveFromAddress);
          this.updateValue("moveToAddress", moveToAddress);
          councilAndWaterfromPreform("oldWater", "oldCouncil", completeAddressTo);
          councilAndWaterfromPreform("newWater", "newCouncil", completeAddressFrom);
        };
      }catch(e){
        console.log("No params")
      }

      this.updateValue("ownedLead", true);




      // this.updateValue("informOldCouncil", true);
      // this.updateValue("informOldWater", true);
      // this.updateValue("informNewCouncil", true);
      // this.updateValue("informNewWater", true);



      // setTimeout(() => {
      //   this.$router.push("/services");
      // }, 300);
    }

    //Logo Param
    let logo = new URLSearchParams(document.location.search).get("logo");

    if (logo) {
      this.$store.commit("formStore/setField", ["referrer", logo]);

      if (paramPreform) {
        navigateTo("/services");
        return;
      }

      navigateTo("/home");
    }

    if (logo && logo == "perx") {
      //Perx - partner
      // this.$store.commit("formStore/setField", ["discountedPrice", 17]);
      this.$store.commit("formStore/setField", ["referrer", "Perx"]);
    }
    if (logo && logo == "MovingHub") {
      //MovingHub - partner
      this.$store.commit("formStore/setField", ["discountedPrice", 10]);
    }

    if (logo && logo == "onTheMarket") {
      //OnTheMarket - partner
      navigateTo("/main");
    }

    //When is manual
    const smfEmail = new URLSearchParams(document.location.search).get("smfEmail");
    const newPostcode = new URLSearchParams(document.location.search).get("smfNewPostcode");
    const oldPostcode = new URLSearchParams(document.location.search).get("smfPrevPostcode");
    const newAddress = new URLSearchParams(document.location.search).get("smfNewAddress");
    const oldAddress = new URLSearchParams(document.location.search).get("smfPrevAddress");

    if (smfEmail) {
      this.updateValue("email", smfEmail);
      // this.updateValue("moveToPostCode", newPostcode);
      // this.updateValue("moveFromPostCode", oldPostcode);
      this.updateValue("moveToAddress", newAddress);
      this.updateValue("moveFromAddress", oldAddress);

      this.setCouncilsWater(newPostcode, oldPostcode);

      this.updateValue("ownedLead", true);

      setTimeout(() => {
        this.$router.push("/services");
      }, 300);
    }
  },
  created() {
    if (isMobile()) {
      let body = document.querySelector("body");
      body.classList.remove("background-body");
    }
    window.intercomSettings = {
      api_base: "https://api-iam.eu.intercom.io",
      app_id: "dyqvf88v",
      //name: `visitor` , // Full customer name
      //email: 'fromTheForm@slothmove.com'// m.userDetails.email the email for the customer
    };
    const intercomChat = () => {
      try {
        if (document.querySelector(".intercom-lightweight-app")) {
          document.querySelector(".intercom-lightweight-app").style.display = "block";
        }

        if (document.querySelector("#intercom-container")) {
          document.querySelector("#intercom-container").style.display = "block";
        }
      } catch (error) {}

      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/dyqvf88v";
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    };
    intercomChat();
    // Color Param
    const color = new URLSearchParams(document.location.search).get("color");

    if (color) {
      document.body.classList.add(`color-${color}`);
    }

    //TaylorRose Flow
    const taylorRose = new URLSearchParams(document.location.search).get("tr");

    if (taylorRose !== null && taylorRose !== undefined) {
      navigateTo("/home");
      this.$store.commit("formStore/setField", ["taylorRoseCode", taylorRose]);
      fetch(this.baseURL + "/encrypted/getTr?s=" + taylorRose, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.$store.commit("formStore/setField", ["email", data.email]);
          this.$store.commit("formStore/setField", ["trId", data.trId]);
          this.$store.commit("formStore/setField", ["referrer", "TaylorRose"]);
          this.$store.commit("formStore/setField", ["priceOffered", 0]);
          // const userExistsTR = await fetch(
          //   `${this.baseURL}/user/utils/existsTR?email=${data.email}`
          // ).then((resp) => resp.json());

          // console.log('User Taylor Rose exist?', userExistsTR);

          // if (userExistsTR == true) {
          //   this.$store.commit("formStore/setField", ["email", data.email]);
          //   this.$store.commit("formStore/setField", ["trId", data.trId]);
          //   this.$store.commit("formStore/setField", ["referrer", "TaylorRose"]);
          // }
        })
        .catch((error) => {
          console.error(error);
          // this.$store.commit('formStore/log', ['TaylorRose', taylorRose])
        });
    } else {
      // if (color) {
      //   document.body.classList.add(`primary-color-${color}`)
      // }
    }

    // const partner = this.$route.query.partner;

    // if (partner) {
    //   this.$store.commit("setField", ["logo", `partners/${partner}.png`]);
    //   document.body.classList.add(`color-${partner}`);
    // }

    let partner = new URLSearchParams(document.location.search).get("th");
    let userPartner = window.atob(partner);

    if (partner) {
      navigateTo("/main");
      this.$store.commit("formStore/setField", ["email", userPartner]);
    }

    //Address changer
    let addressUpdaterKey = new URLSearchParams(document.location.search).get("addressUpdater");
    let familyUpdaterKey = new URLSearchParams(document.location.search).get("familyUpdater");

    // console.log({addressUpdaterKey,familyUpdaterKey})

    let options = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (addressUpdaterKey) {
      navigateTo("/home");
      fetch(`${this.baseURL}/encrypted/getAddressUpdaterRequest?s=${addressUpdaterKey}`, options)
        .then((res) => {
          res.json().then(({ email }) => {
            this.$store.commit("formStore/setField", ["priceOffered", 0]);

            this.updateValue("email", email);
            //referrer
            this.updateValue("referrer", "AddressUpdater");
          });
        })
        .catch((e) => {});
    }

    if (familyUpdaterKey) {
      navigateTo("/home");

      fetch(`${this.baseURL}/encrypted/getFamilyUpdaterRequest?s=${familyUpdaterKey}`, options)
        .then((res) => {
          res.json().then((data) => {
            this.$store.commit("formStore/setField", ["priceOffered", 0]);

            this.updateValue("email", data.email);
            this.updateValue("moveFromAddress", data.moveFromAddress.replace("FROM", ""));
            this.updateValue("moveFromPostCode", data.moveFromPostcode);
            this.updateValue("moveToAddress", data.moveToAddress.replace("TO", ""));
            this.updateValue("moveToPostCode", data.moveToPostcode);
            //referrer
            this.updateValue("referrer", "FamilyUpdater");

            //disabling address inputs to no-editable
            let inputs = document.getElementsByClassName("vs__search");

            for (let index = 0; index < inputs.length; index++) {
              const element = inputs[index];
              element.style.display = "none";
            }
          });
        })
        .catch((e) => {});
    }
  },
  methods: {
    // isFrontPage(){
    //   return  window.location.pathname == '/' ? '415px' : '550px'
    // },
    setCouncilsWater(moveToPostCode, moveFromPostCode) {
      const getFullComplete = (id, water, council, postcodetype, completeAddress) => {
        fetch(`${this.baseURL}/addresses/getFullComplete?stream=${id}`, {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          res.json().then((json) => {

            this.updateValue(completeAddress, json);
            this.updateValue(postcodetype, json.postcode);

            this.$store.commit("formStore/setField", [
              water,
              pcToWater[json.postcode.split(" ")[0]],
            ]);
            this.$store.commit("formStore/setField", [council, json.district ?? json.county]);
          });
        });
      };

      const addressFinderId = (postcode, water, council, p, ca) => {
        fetch(`${this.baseURL}/addresses/autoComplete?stream=${postcode}`, {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((res) => {
            let id = res.suggestions[0].id;
            getFullComplete(id, water, council, p, ca);
          });
      };

      addressFinderId(
        moveToPostCode,
        "newWater",
        "newCouncil",
        "moveToPostCode",
        "completeMoveToAddress"
      );
      addressFinderId(
        moveFromPostCode,
        "oldWater",
        "oldCouncil",
        "moveFromPostCode",
        "completeMoveFromAddress"
      );
    },
    updateValue(itemName, value) {
      this.$store.commit("formStore/setField", [`${itemName}`, value]);
    },
    calculateTimeMoneySaved() {
      let timeSaved = 0;
      let moneySaved = 0;

      let services = [
        { NHS: { time: 1, money: 16.14 } },
        { DVLA: { time: 2.5, money: 40.35 } },
        { LOYALTY: { time: 0.5, money: 8.07 } } /* {per} */,
        { SLC: { time: 0.5, money: 8.07 } },
        { BANKS: { time: 1, money: 16.14 } } /* {per} */,
        { ELECTORAL: { time: 1, money: 16.14 } },
        { HMRC: { time: 2, money: 32.28 } },
        { CHARITY: { time: 0.5, money: 8.07 } } /* {per} */,
        { BONDS: { time: 1, money: 16.14 } },
        { TVLICENSE: { time: 1, money: 16.14 } },
        { LOTTERY: { time: 1, money: 16.14 } },
        { PENSION: { time: 1, money: 16.14 } } /* {per} */,
        { ENERGY: { time: 1, money: 16.14 } },
        { GYMS: { time: 0.5, money: 8.07 } } /* {per} */,
        { DWP: { time: 1.5, money: 24.21 } },
        { MOBILE: { time: 0.5, money: 8.07 } },
        { COUNCIL: { time: 1.5, money: 24.21 } },
        { WATER: { time: 1, money: 16.14 } },
      ];

      const setTimeMoney = (timeService, moneyService) => {
        timeSaved += timeService;
        moneySaved += timeService * moneyService;
      };

      services.forEach((serv) => {
        if (serv.hasOwnProperty("NHS") && this.informNHS) {
          setTimeMoney(serv.NHS["time"], serv.NHS["money"]);
        }

        if (serv.hasOwnProperty("DVLA") && this.informDVLA) {
          setTimeMoney(serv.DVLA["time"], serv.DVLA["money"]);
        }

        if (serv.hasOwnProperty("LOYALTY") && this.informLoyalty) {
          setTimeMoney(serv.LOYALTY["time"] * this.selectedLoyalties.length, serv.LOYALTY["money"]);
        }

        if (serv.hasOwnProperty("SLC") && this.informStudentFinance) {
          setTimeMoney(serv.SLC["time"], serv.SLC["money"]);
        }

        if (serv.hasOwnProperty("BANKS") && this.informBanks) {
          setTimeMoney(serv.BANKS["time"] * this.selectedBanks.length, serv.BANKS["money"]);
        }

        if (serv.hasOwnProperty("ELECTORAL") && this.informElectoralRoll) {
          setTimeMoney(serv.ELECTORAL["time"], serv.ELECTORAL["money"]);
        }

        if (serv.hasOwnProperty("HMRC") && this.informHMRC) {
          setTimeMoney(serv.HMRC["time"], serv.HMRC["money"]);
        }

        if (serv.hasOwnProperty("CHARITY") && this.informCharities) {
          setTimeMoney(serv.CHARITY["time"] * this.selectedCharities.length, serv.CHARITY["money"]);
        }

        if (serv.hasOwnProperty("BONDS") && this.informBonds) {
          setTimeMoney(serv.BONDS["time"], serv.BONDS["money"]);
        }

        if (serv.hasOwnProperty("TVLICENSE") && this.informTVLicense) {
          setTimeMoney(serv.TVLICENSE["time"], serv.TVLICENSE["money"]);
        }

        if (serv.hasOwnProperty("LOTTERY") && this.informLottery) {
          setTimeMoney(serv.LOTTERY["time"], serv.LOTTERY["money"]);
        }

        if (serv.hasOwnProperty("PENSION") && this.informPension) {
          setTimeMoney(serv.PENSION["time"] * this.pensionSelection.length, serv.PENSION["money"]);
        }

        if (serv.hasOwnProperty("ENERGY") && this.informEnergy) {
          setTimeMoney(serv.ENERGY["time"], serv.ENERGY["money"]);
        }

        if (serv.hasOwnProperty("GYMS") && this.informGyms) {
          setTimeMoney(serv.GYMS["time"] * this.selectedGyms.length, serv.GYMS["money"]);
        }

        if (serv.hasOwnProperty("DWP") && this.informDWP) {
          setTimeMoney(serv.DWP["time"], serv.DWP["money"]);
        }

        if (serv.hasOwnProperty("MOBILE") && this.informMobile) {
          setTimeMoney(serv.MOBILE["time"] * this.mobileSelection.length, serv.MOBILE["money"]);
        }

        if (serv.hasOwnProperty("COUNCIL") && this.informNewCouncil) {
          setTimeMoney(serv.COUNCIL["time"], serv.COUNCIL["money"]);
        }

        if (serv.hasOwnProperty("WATER") && this.informNewWater) {
          setTimeMoney(serv.WATER["time"], serv.WATER["money"]);
        }

        this.$store.commit("formStore/setField", [`timeSaved`, timeSaved]);
        this.$store.commit("formStore/setField", [`moneySaved`, moneySaved]);
      });
    },
    calculatePricing() {
      let allServices = [
        this.informDWP,
        this.informHMRC,
        this.informDVLA,
        this.informNHS,
        this.informElectoralRoll,
        this.informDentist,
        this.informBonds,
        this.informLottery,
        this.informEnergy,
        this.informTVLicense,
        this.informStudentFinance,
        this.informNewCouncil,
        this.informNewWater,
        this.informOldCouncil,
        this.informOldWater,
        this.informCharities,
        this.informBanks,
        this.informLoyalty,
        this.informPension,
        this.informGyms,
        this.informMobile,
        ...this.pensionSelection.map((item) => item.name),
        ...this.mobileSelection.map((item) => item.name),
        ...this.selectedBanks.map((item) => item.name),
        ...this.selectedGyms.map((item) => item.name),
        ...this.selectedCharities.map((item) => item.name),
        ...this.selectedLoyalties.map((item) => item.name),
      ];

      let quantityTrue = 0;
      let quantityFalse = 0;
      // console.log(Object.values(allServices));

      Object.values(allServices).forEach((service) => {
        if (service) ++quantityTrue;
        if (!service) ++quantityFalse;
      });

      // console.log({ informsTrue: quantityTrue, informsFalse: quantityFalse });

      if (quantityTrue == 1) {
        this.$store.commit("formStore/setField", [`priceOffered`, 5]);
      }

      if (quantityTrue == 2) {
        this.$store.commit("formStore/setField", [`priceOffered`, 10]);
      }

      if (this.informDVLA || quantityTrue >= 3) {
        this.$store.commit("formStore/setField", [`priceOffered`, 20]);
      }

      //Partners

      if (this.referrer == "TaylorRose") {
        this.$store.commit("formStore/setField", ["priceOffered", 0]);
      }

      if (this.referrer == "Perx") {
        this.$store.commit("formStore/setField", ["priceOffered", 18]);
      }

      if (this.referrer == "MovingHub") {
        this.$store.commit("formStore/setField", ["priceOffered", 25]);
      }
    },
    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    getLargeCookie(name) {
      let value = "";
      let i = 0;
      while (true) {
        let chunk = this.getCookie(name + i);
        if (!chunk) {
          break;
        }
        value += chunk;
        i++;
      }
      return value;
    },
    deleteCookie(cname) {
      document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    },
    deleteLargeCookie(name) {
      let i = 0;
      while (true) {
        let chunk = this.getCookie(name + i);
        if (!chunk) {
          break;
        }
        deleteCookie(name + i);
        i++;
      }
    },
  },
  computed: {
    ...mapState(["currentTooltip", "isLoading"]),
    ...mapState("formStore", [
      "baseURL",
      "email",
      "referrer",
      "priceOffered",
      "informNewCouncil",
      "informNewWater",
      "informOldCouncil",
      "informOldWater",
      "informDentist",
      "informEnergy",
      "informDWP",
      "informDVLA",
      "informHMRC",
      "informStudentFinance",
      "informElectoralRoll",
      "informNHS",
      "informSpam",
      "informBonds",
      "informTVLicense",
      "informLottery",
      "informCharities",
      "informBanks",
      "informLoyalty",
      "informPension",
      "informGyms",
      "informMobile",
      "selectedLoyalties",
      "selectedBanks",
      "selectedCharities",
      "selectedGyms",
      "pensionSelection",
      "mobileSelection",
    ]),
  },
  watch: {
    $route(to, from) {
      // this.$store.commit("setField", ["isLoading", true]);
      scrollToTop();
      this.calculateTimeMoneySaved();
      // this.calculatePricing();

      if (this.referrer == "tiktok" && this.email.length > 0 && !this.tiktokStarted) {
        tiktokAnalytics(this.email, "ViewContent", this.priceOffered);
        this.tiktokStarted = true;
      }
    },
  },
};
</script>

<style lang="scss">
.main-container {
  min-height: 97vh;
  max-width: 550px;
}

.layout-router-view {
  position: sticky;
  z-index: 11;
}

aside {
  height: 70% !important;
  background-color: transparent !important;
}

.action-sheet {
  border-radius: 40px 40px 0px 0px;
  background: #fff;
  max-width: 540px;

  button {
    border: none;
    background-color: transparent;
  }
}

.pushbar_overlay {
  background: #3c344200 !important;
  backdrop-filter: blur(13px) !important;
}

.content-loaded {
  visibility: visible !important;
}

div.swal2-container.swal2-center.swal2-backdrop-show {
  .swal2-popup {
    width: 55em;
    padding: 0px !important;
    border-radius: 30px !important;
  }

  .swal2-html-container {
    margin: 0px !important;
  }
}
</style>
